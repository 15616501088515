@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); 
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

$width: 100%;
$primary: #e07720; 
$red: #C1272D;
$grey: #D3D3D3;
$darkgrey: #9C9C9C;

body {
  margin: 0;
  font-family:'Roboto', 'Montserrat', 'Segoe UI', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
} 

span, input, h1, select, p { 
  font-family: 'Montserrat', 'Segoe UI', sans-serif;
}

select { 
  font-size: 12px;
}

button { 
  font-family:'Roboto', 'Segoe UI', sans-serif;
} 

input, select { 
  &:focus {
    outline: none;
  }  
} 

.index {
  position: relative;

  input  {    
    &:focus ~ .bar:before {
      width: $width; 
    } 
  } 
} 

.bar {
  position: relative;
  display: block;
  width: $width;
  &:before {
    content: '';
    height: 1px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: $primary;
    transition: 300ms ease all;
    left: 0%;
  }
}

.eyes{ 
  position: absolute;
  cursor: pointer;
  right: 0px;  
  padding: 6px;  
  font-size: 20px;
  color: #C1272D;
}

.red {
  font-size: 15px;
  color: $red;
}

.pencil { 
  position: absolute;
  cursor: pointer;
  right: 0px;  
  padding: 12px;  
  font-size: 14px;
}

.brand-logo {
  height: 120px;
  margin: auto;
} 

.centercontainer {
  width: 20rem;
  z-index: 1;
}

.card-center {
  max-width: 550px; 
  min-width: 300px;
  padding: 3rem;
  flex: 1 1;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 1200px) {
    box-shadow: none;
 }

}

input[type=checkbox]:checked {
  background-color: #ff7f14 !important;  
  width: 15px !important;
  height: 15px !important; 
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  outline: 1px solid #ff7f14;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

input[type=checkbox] {  
  width: 15px !important;
  height: 15px !important;
  margin: -2px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance:none;
  outline: 1px solid #bbb;
  box-shadow: none;
  font-size: 2em;
  border-radius: 2px;
}

// Main Content
.main-content {
  transition: margin-left 300ms;
  margin-left: 180px; 
  min-height: 100vh;
  height: 100vh;
  background: #f6f6f6;
} 

main {     
  overflow-y: auto; 
} 

.layout {
  margin-top: 60px;

  &-content {
    overflow: auto;
    height: 90vh; 

    &-main {
      padding: 1rem;     
      padding-bottom: 2rem;
    }
  }
}

// Antd Switch
.ant-switch {
  background-color: red !important;
}

.ant-switch.ant-switch-checked {
  background-color: #33cd59 !important;
}

// Antd Select
.ant-select-selector {
  border-bottom: 1px solid $grey !important;
  border-top: 1px solid white !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}

.ant-select-selector:hover, .ant-select-selector:focus-within {
  border-bottom: 1px solid $primary !important;
  border-top: 1px solid white !important;
  box-shadow: none !important; 
  border-radius: 0 !important;
}

// Antd Tab
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $primary !important;  
}

.ant-tabs-tab:hover, .ant-tabs-tab:active{
  color: $primary !important; 
} 

.ant-tabs .ant-tabs-tab-btn:active {
  color: $primary !important;  
}

.ant-tabs-ink-bar {
  position: absolute;
  background: $primary !important;
  pointer-events: none;
}

.status {
  &_icon {
    height: 1.25rem /* 20px */;
    width: auto;
    margin-left: 0;
    margin-right: 0.125rem /* 2px */;
    cursor: pointer;
  } 

  &_draft {
    color: #747474; 
  }
  &_pending {
    color: #747474; 
  }
  &_accepted { 
    color: #dea300;  
  } 
  &_canceled {
    color: #de0000; 
  }
  &_delivered {
    color: #61BF6A; 
  }   
 
  &_for_pickup {
    color: #EAA774; 
  }
  &_on_going {
    color: #2db7f5; 
  }
  &_picked_up {
    color: #ce7501; 
  }  
}

.note { 
  border: 1px solid #6cb1ff;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 1rem;
  background: rgb(162 193 244 / 19%);
  font-size: 12px;
  color: #045dec;
  font-weight: 600;
}

// Shop
.shop {
  border-top: 1px solid $grey; 
  padding: 1rem 0;
  margin: 1rem 0;
}

.card-shop {
  border-bottom: 1px solid $grey;
  padding: 0.5rem;
  margin: 1rem 0.5rem;
  border: 1px solid #d3d3de;

 .product-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }

  .item {
      font-size: 18px;
      font-weight: 600;
      color: $primary;
  }

  .category { 
      font-size: 15px; 
      color: $darkgrey;
      font-style: italic;
  }

  .unit {
      font-size: 12px; 
      color: $darkgrey;
      font-style: italic; 
  }

  .added {
      color: #c1272d; 
      font-size: 15px; 
      display: flex;
      margin: auto;
      justify-content: center;
  }
}

// Orders 
.orders-content {
  margin: 1rem 0; 

  .order-img {
    width: 100%;
    height: 85px;
    object-fit: cover;
  }

  .td-order-img {
    width: 100px;
    margin: 1rem;
    padding: 0.5rem;
  }

  .card-orders { 
    margin: 1rem;
    border-top: 1px solid #d3d3d3;
    padding: 1rem;

    &-body {
      margin:  0 1rem;  
    } 
    
    .item { 
      font-size: 18px;
      font-weight: 600;
      color: $primary;
    }

    .category { 
      font-size: 15px; 
      color: $darkgrey;
      font-style: italic;
    }
  
    .unit {
        font-size: 12px; 
        color: $darkgrey;
        font-style: italic; 
    }
  }

  .center {
    text-align: center;
  }
}

.order { 
  &-summary {
    background: #f3f3f3;
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin: 0 1rem;
    padding: 1rem;
  }

  &-total {
    border-top: 1px solid #dfdfdf;
    padding-top: 0.5rem;
    margin: 0.5rem 0;
    display: flex;
    justify-content: space-between;
  }
}


.config-form {
  margin-bottom: 1em;
  width: 100%;

  label {
    width: 150px;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }
}

.h-13 {
  height: 5rem;
}

:where(.css-dev-only-do-not-override-ed5zg0).ant-input:hover,
:where(.css-dev-only-do-not-override-ed5zg0).ant-input:active { 
  border-color: #ff7f14 !important;
  box-shadow: none !important;
}

.qty-btn {
  background: #F3F3F3;
  padding: 0 0.5rem;
}

.qty-btn:disabled { 
  background: #9C9C9C;
}


.qty-btn:hover { 
  background: #9C9C9C;
  color: black;
}

.column-img {
  width: 100%;
  height: 50px;
  object-fit: cover;
}

.column-order-img {
  width: 50px; 
}

.empty-cart {
  height: 75vh; 
}

.purchase-view {
  &-title {
    border-bottom: 1px solid #bbb;
    border-style: dashed;
  }
}

.card-scroll {
  border: 1px solid #cecece;
  border-radius: 0.5rem;
  overflow: visible;
  height: 120px;
  overflow-y: scroll;
  padding: 0.5rem;
  margin: 1rem .5rem;

  &::-webkit-scrollbar {
    width: 15px; 
  }
         
  &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px $darkgrey; 
      border-radius: 10px;
  }
   
  &::-webkit-scrollbar-thumb {
      background:  $darkgrey ; 
      border-radius: 10px; 
  } 

  &::-webkit-scrollbar-thumb:hover {
      background: #ff7f14; 
  }
}

// Upload image
.avatar-uploader {
  img {
    width: 100px;
    height: 100px; 
    object-fit: cover;
    border-radius: 0.5rem;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-bottom: 1px;
  }
}

.link-supplier {
  cursor: pointer;

  // &:hover {
  //   text-decoration-line: underline;
  // }
}
