.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

button:disabled,
button[disabled],
button:hover:disabled,
button:hover[disabled]{ 
  background-color: #9C9C9C;
  border: 1px solid  #9C9C9C;
  color: #fff !important;
  /* letter-spacing: 0.025em; */
  border: none;    
  opacity: 0.5;
}  

/* Remove arrow in number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
 
input[type="text"]:disabled, input[type="email"]:disabled{
  color: #c1c0c0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
