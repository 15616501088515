$primary: #e07720;

.ecom {
    display: flex;
    justify-content: center;
    border: 1px solid #e07720;
    background: white;
    border-radius: 0.5rem;

    &-title {
        background: $primary;
        border: 1px solid $primary;
        border-radius: 0.35rem 0.35rem 0 0; 
        text-align: center; 
        color: white;
        padding: 0.2rem 0;
    }

    &-card {
        width: 100%;

        &-img { 
        justify-content: center; 
        display: flex; 
        
        img {
                height: 200px; 
                display: flex;
                justify-content: center;
            }
        } 
    }
    
    &-status {
        width: 130px;
        display: flex;
        justify-content: center;
        margin: auto;
        align-items: center;
        border-width: 1px;
        border-radius: 2px; 
    }
    
}
 